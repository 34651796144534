<template>
  <div class="create-links">
    <CForm
      ref="createLinks"
      novalidate
      class="create-form"
    >
      <div class="form-content content">
        <h3 class="mb-3 bread-content">{{ texts.links.title }}</h3>
        <!--    FROM    -->
        <CIInput
          :label="texts.links.entityId"
          :tooltip="''"
          v-model="formData.entityId"
          :placeholder="'Entity Id'"
          :disabled="false"
          :hidden="false"
          :required="true"
          :name="'entityId'"
        />
        <CISelectEntites
          :label="texts.links.entityType"
          :tooltip="''"
          :disabled="false"
          :hidden="false"
          :selectLabel="'selectLabel'"
          :multiple="false"
          :searchable="true"
          :required="true"
          v-model="formData.entityType"
        />
        <ZqSelectField
          v-model="formData.linkType"
          :name="'linkType'"
          :tooltip="''"
          :label="texts.links.linkType"
          :toolTipText="''"
          :placeholder="'Link Type'"
          :disabled="false"
          :required="true"
          :options="linkTypeOptions"
          :selectLabel="'Link Type'"
          :multiple="false"
          :taggable="false"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import { memberMessagesTexts } from "@/config/pageTexts/memberMessages.json";
import ZqSelectField from '@/shared/components/ZqSelectField';

export default {
  name: "CreateLinks",
  components: {
    ZqSelectField,
  },
  data() {
    return {
      texts: {
        ...memberMessagesTexts
      },
      formData: {
        entityId: null,
        entityType: null,
        linkType: null,
      },
      linkTypeOptions: ['relatesTo', 'refersTo', 'describes'],
      entityTypes: {},
    }
  },
  methods: {},
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('updateLinksData', val);
      },
    },
  }
}
</script>

<style scoped>

</style>